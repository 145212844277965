import { debounce } from 'lodash';

<template>
  <assign-persons
    ref="assignPersons"
    :title="$t('message.admin.edit_group_management')"
    :name="form.name"
    :assignedList="getGroupStudents"
    :detachList="getStudents"
    :loading="isLoading"
    @updateName="updateGroupName"
    @assigned="assignStudents"
    @detach="detachStudents"
    @searchTextByAssigned="searchTextByAssigned"
    @searchTextByDetach="searchTextByDetach"
  />
</template>

<script>

import { mapGetters } from 'vuex';

import AssignPersons from '@components/Shared/AssignPersons.vue';
import { debounce } from 'lodash';

export default {
  name: 'editGroupManagement',
  components: {
    AssignPersons,
  },
  props: {},
  computed: {
    ...mapGetters({
      groupLoading: 'group/isLoading',
      groupsLoading: 'groups/isLoading',
      group: 'group/getGroup',
      getStudents: 'groups/getStudents',
    }),
    isLoading() {
      return this.groupLoading || this.groupsLoading;
    },
    getGroupStudents() {
      let students = [];

      if (this.group && this.group.group_students) {
        students = this.group.group_students;
      }

      return students;
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchStudents();
      return this.fetchGroup(this.$route.params.id);
    },
    fetchGroup(id, params) {
      return this.$api.groups.getGroup(id, params).then((res) => {
        const { data } = res;
        this.form.name = data.name;
      }).catch((err) => {
        if (err.response.status === 404) {
          this.$router.push('/admin/group-management');
          this._showErrorNotify(this.$t('message.admin.one_or_several_groups_was_removed_already_please_reload_page'));
        }
      });
    },
    fetchStudents() {
      this.$api.groups.getStudents();
    },


    updateGroupName(name) {
      const groupId = this.$route.params.id;
      const formData = { name };

      this.$api.groups.updateGroup(formData, groupId).then((res) => {
        this.form.name = res.group.data.name;
      });
    },
    assignStudents(attachIds) {
      const groupId = this.$route.params.id;
      const formData = { ids: attachIds };

      this.$api.groups.assignStudents(formData, groupId).then(async () => {
        await this.fetchData();
        this.resetDetach();
        this.resetAttach();
      });
    },
    detachStudents(detachArray) {
      const groupId = this.$route.params.id;
      const formData = { ids: detachArray };

      this.$api.groups.detachStudents(formData, groupId).then(async () => {
        await this.fetchData();
        this.resetDetach();
        this.resetAttach();
      });
    },
    // eslint-disable-next-line func-names,camelcase
    searchTextByAssigned: debounce(function (search_student_query) {
      this.fetchGroup(this.$route.params.id, { search_student_query });
    }, 700),
    // eslint-disable-next-line func-names
    searchTextByDetach: debounce(function (search) {
      this.$api.groups.getStudents({ search });
    }, 700),

    resetAttach() {
      this.$refs.assignPersons.resetAttach();
    },
    resetDetach() {
      this.$refs.assignPersons.resetDetach();
    },
  },
};
</script>
