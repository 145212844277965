<template>
  <v-container>
    <Preloader overlay v-if="loading" />
    <div class="headline mb-6 mt-1">
     {{ title }}
    </div>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" >
          <v-row>
            <v-col cols="12" sm="4">
              <div class="form-group">
                <v-text-field
                  id="name"
                  v-model="form.name"
                  outlined
                  :placeholder="$t('message.name')"
                  :rules="[_rules.required, _rules.aplhWithSpaceWithDash]"
                ></v-text-field>
                <label class="label" for="name">
                  {{$t('message.name')}}
                  <i class="warning-icon">!</i>
                </label>
                <v-btn
                  class="d-block mb-1 ml-auto m-button m-button_success"
                  text
                  @click.prevent="updateName"
                  :disabled="loading || isDefaultName"
                >
                  {{$t('message.admin.save_name')}}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="title mb-4">
          {{ $t('message.admin.ready_to_attach_participants') }}
        </div>
        <v-text-field
          class="treeview__search mb-5 col-8"
          rounded
          flat
          solo
          color="#595959"
          v-model="searchByDetach"
          @input="searchByTextByDetach"
          background-color="#F7F7F7"
          prepend-inner-icon="fa-search"
          :label="$t('message.search') + '...'"
          :disabled="loading"
          ref="searchByDetach"
        />
        <v-checkbox
          class="mt-0"
          :key="item.id"
          v-for="item in detachList"
          v-model="attach"
          :label="item.full_name"
          :value="item.id">
        </v-checkbox>
      </v-col>
      <v-col cols="6">
        <div class="title mb-4">
          {{ $t('message.admin.assigned_participants') }}
        </div>
        <v-text-field
          class="treeview__search mb-5 col-8"
          rounded
          flat
          solo
          color="#595959"
          v-model="searchByAssigned"
          @input="searchByTextByAssigned"
          background-color="#F7F7F7"
          prepend-inner-icon="fa-search"
          :label="$t('message.search') + '...'"
          :disabled="loading"
          ref="searchByAssigned"
        />
        <v-checkbox
          multiple
          class="mt-0"
          :key="item.id"
          v-for="item in assignedList"
          v-model="detach"
          :label="item.full_name"
          :value="item.id">
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-btn
          class="d-block mb-3 mx-auto m-button m-button_success"
          text
          @click.prevent="assignPersons"
          :disabled="loading || !isAttachEmpty"
        >
          {{ $t('message.admin.assigned') }}
        </v-btn>
        <v-btn
          class="d-block m-button m-button_danger mx-auto"
          text
          @click.prevent="detachPersons"
          :disabled="loading || !isDetachEmpty"
        >
          {{ $t('message.admin.detach') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import Preloader from '@components/Shared/Preloader.vue';
import rulesMixin from '@/mixins/Form/rules';

export default {
  name: 'AssignPersons',
  components: {
    Preloader,
  },
  props: {
    title: {
      type: String,
    },
    name: {
      type: String,
      default: '',
    },
    assignedList: {
      type: Array,
      default: () => [],
    },
    detachList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      group: 'group/getGroup',
    }),
    isAttachEmpty() {
      return Boolean(this.attach.length);
    },
    isDetachEmpty() {
      return Boolean(this.detach.length);
    },
    isDefaultName() {
      return this.form.name === this.name;
    },
  },
  watch: {
    name: {
      handler(newValue) {
        this.form.name = newValue;
      },
    },
  },
  mixins: [
    rulesMixin,
  ],
  data() {
    return {
      form: {
        name: '',
      },
      searchByDetach: '',
      searchByAssigned: '',
      attach: [],
      detach: [],
    };
  },
  methods: {
    updateName() {
      if (!this.formValidate(this.$refs.form)) return;
      this.$emit('updateName', this.form.name);
    },
    assignPersons() {
      this.$emit('assigned', this.attach);
    },
    detachPersons() {
      this.$emit('detach', this.detach);
    },
    resetAttach() {
      this.attach = [];
      this.searchByAssigned = '';
    },
    resetDetach() {
      this.detach = [];
      this.searchByDetach = '';
    },
    searchByTextByAssigned(data) {
      this.$emit('searchTextByAssigned', data);
      setTimeout(() => {
        this.$refs.searchByAssigned.focus();
      }, 1500);
    },
    searchByTextByDetach(data) {
      this.$emit('searchTextByDetach', data);
      setTimeout(() => {
        this.$refs.searchByDetach.focus();
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
  .v-input__control{
    .v-messages theme--light{
      display: none;
    }
  }
</style>
